<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1" v-if="settObj">
          <b-col md="12">
            <b-form-group
              label="Opening Balance"
              invalid-feedback="Opening Balance is required."
              ref="bal"
            >
              <b-form-input
                placeholder="Enter balance here"
                v-model="settObj.valueNumber"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              variant="success"
              @click="saveSetting()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Select Month"
              invalid-feedback="Month is required."
              ref="month"
            >
              <v-select
                v-model="dateObj.month"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select month"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Select Year"
              invalid-feedback="year is required."
              ref="year"
            >
              <v-select
                v-model="dateObj.year"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :clearable="false"
                placeholder="Select year"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                :config="config"
                v-model="rangeDate"
                class="form-control"
                placeholder="Select Date."
                @on-change="setDate"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mt-1">
            <b-button
              variant="success"
              @click="saveData()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Generate</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Select Month"
            invalid-feedback="Month is required."
            ref="month"
          >
            <v-select
              v-model="dateObj.month"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="months"
              label="text"
              :reduce="(opt) => opt.value"
              :clearable="false"
              placeholder="Select month"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Select Year"
            invalid-feedback="year is required."
            ref="year"
          >
            <v-select
              v-model="dateObj.year"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="years"
              :clearable="false"
              placeholder="Select year"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-button
            variant="primary"
            :disabled="dataLoading || exporting"
            class="mt-2 mr-1"
            @click="LoadData()"
          >
            <span class="align-middle">Show Report</span>
          </b-button>
          <!-- <b-button
            variant="primary"
            :disabled="dataLoading || exporting"
            class="mt-2 mr-1"
            @click="LoadSummary()"
          >
            <span class="align-middle">Show Summary</span>
          </b-button> -->
          <b-button
            variant="outline-primary"
            class="btn-icon mt-2"
            v-b-tooltip.hover.top
            title="Export Report"
            @click="exportData()"
            :disabled="dataLoading || exporting"
          >
            <b-spinner v-if="exporting" small />
            <feather-icon v-else icon="DownloadIcon" />
          </b-button>
        </b-col>
      </b-row>
      <!-- <b-button
        variant="outline-primary"
        class="btn-icon ml-1"
        v-b-tooltip.hover.top
        title="Set Opening Balance"
        @click="openSetting()"
        :disabled="settLoading"
      >
        <b-spinner v-if="settLoading" small />
        <feather-icon v-else icon="SettingsIcon" />
      </b-button> -->

      <b-table
        v-if="isSummary"
        class="mt-1"
        :items="summItems"
        :fields="summfields"
        :busy="dataLoading"
        responsive
        show-empty
        small
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(srNo)="data">
          <b-badge variant="light-primary" class="mb-50">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(cls)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(fee)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(clear_ratio)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(default_ratio)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(total_std)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(clear_stds)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(unclear_stds)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(projection)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(cleared)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(default)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <!-- <template #cell(monthly)="data">
          <div
            v-for="(ff, fi) in data.item.monthly_fee"
            :key="fi"
            class="mb-50"
          >
            <b-badge variant="light-primary">
              {{ ff.title }} - {{ ff.amount }}
            </b-badge>
          </div>
        </template>
        <template #cell(other)="data">
          <div v-for="(ff, fi) in data.item.other_fee" :key="fi" class="mb-50">
            <b-badge variant="light-primary">
              {{ ff.title }} - {{ ff.amount }}
            </b-badge>
          </div>
        </template>
        <template #cell(total)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template> -->
      </b-table>

      <b-table
        v-else
        class="mt-1"
        :items="items"
        :fields="fields"
        :busy="dataLoading"
        responsive
        show-empty
        small
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(srNo)="data">
          <b-badge variant="light-primary" class="mb-50">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(cls)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(voucher_issued)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(voucher_cleared)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(voucher_unpaid)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(monthly)="data">
          <div
            v-for="(ff, fi) in data.item.monthly_fee"
            :key="fi"
            class="mb-50"
          >
            <!-- toLocaleString -->
            <b-badge variant="light-primary">
              {{ ff.title }} - {{ ff.amount }}
            </b-badge>
          </div>
        </template>
        <template #cell(other)="data">
          <div v-for="(ff, fi) in data.item.other_fee" :key="fi" class="mb-50">
            <!-- toLocaleString -->
            <b-badge variant="light-primary">
              {{ ff.title }} - {{ ff.amount }}
            </b-badge>
          </div>
        </template>
        <template #cell(total)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BFormSpinbutton,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";
import * as xlsx from "xlsx";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormSpinbutton,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      config: {
        mode: "range",
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      dataLoading: false,
      exporting: false,
      items: [],
      fields: [
        { label: "srNo", key: "srNo" },
        { label: "cls", key: "cls" },
        { label: "voucher issued", key: "voucher_issued" },
        { label: "voucher cleared", key: "voucher_cleared" },
        { label: "voucher unpaid", key: "voucher_unpaid" },
        { label: "monthly fee", key: "monthly", tdClass: "align-top" },
        { label: "other fee", key: "other", tdClass: "align-top" },
        { label: "total", key: "total" },
      ],
      summItems: [],
      summfields: [
        { label: "srNo", key: "srNo" },
        { label: "cls", key: "cls" },
        { label: "fee", key: "fee" },
        { label: "clear ratio", key: "clear_ratio" },
        { label: "default ratio", key: "default_ratio" },
        { label: "total stds", key: "total_std" },
        { label: "clear stds", key: "clear_stds" },
        { label: "unclear stds", key: "unclear_stds" },
        { label: "projection", key: "projection" },
        { label: "cleared", key: "cleared" },
        { label: "default", key: "default" },
      ],
      isSummary: false,
      visibility: false,
      settLoading: false,
      settObj: null,
      request: false,
      visibility2: false,
      rangeDate: null,
      dateObj: {
        dtFrom: null,
        dtTo: null,
        month: 0,
        year: 0,
      },
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      years: [
        2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026,
        2027, 2028, 2029, 2030,
      ],
      saving: false,
    };
  },
  created() {
    // this.$store.commit("setReconilData", []);
    // this.items = this.$store.state.reconilData;
    const dt = new Date();
    this.dateObj.month = dt.getMonth() + 1;
    this.dateObj.year = dt.getFullYear();
    // this.LoadData();
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    monthName(val) {
      let obj = this.months.find((el) => el.value == val);
      return obj ? obj.text.slice(0, 3) : "";
    },

    AddOpen() {
      if (!this.rangeDate) {
        const fd = new Date();
        const firstDate = `${fd.getFullYear()}-${(fd.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-01`;

        const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
        const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;

        this.dateObj.dtFrom = firstDate;
        this.dateObj.dtTo = lastDate;
        this.rangeDate = `${firstDate} to ${lastDate}`;

        this.dateObj.month = fd.getMonth() + 1;
        this.dateObj.year = fd.getFullYear();
      }
      this.visibility2 = true;
      var elem = this.$refs["date"];
      elem.state = undefined;
    },
    setDate(sd, dateStr) {
      // console.log(sd, dateStr);
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.dateObj.dtFrom = date[0];
            this.dateObj.dtTo = date[0];
          } else {
            this.dateObj.dtFrom = date[0];
            this.dateObj.dtTo = date[1];
          }
        } else {
          this.dateObj.dtFrom = dateStr;
          this.dateObj.dtTo = null;
        }
      } else {
        this.dateObj.dtFrom = null;
        this.dateObj.dtTo = null;
      }
      // console.log(this.dateObj.dtFrom, this.dateObj.dtTo);
    },
    async saveData() {
      var elem = this.$refs["date"];
      if (this.dateObj.dtFrom && this.dateObj.dtTo) {
        elem.state = true;
        // console.log(this.dateObj);

        this.saving = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/saveReconcilation?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.dateObj,
          // message: "Account added successfully.",
          context: this,
          token: this.$store.state.userData.token,
          subjects: true,
        });
        this.saving = false;
        // console.log(status);
        if (status) {
          // this.items.push(status);
          // this.$store.commit("setReconilData", this.items);
          this.visibility2 = false;
          this.LoadData();
        }
      } else {
        elem.state = false;
      }
    },

    async openSetting() {
      this.settLoading = true;
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: ["reconcil-opening"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });

      let obj = res.find((el) => el.key == "reconcil-opening");
      if (!obj) {
        this.settObj = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          key: "reconcil-opening",
          value: "reconcil-opening",
          valueBool: true,
          valueNumber: 0,
        };
      } else this.settObj = obj;

      this.settLoading = false;
      this.visibility = true;
      this.$nextTick(() => {
        var elem = this.$refs["bal"];
        elem.state = undefined;
      });
    },
    CheckVal() {
      var elem = this.$refs["bal"];
      let x = parseInt(this.settObj.valueNumber);
      if (isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveSetting() {
      if (this.CheckVal() == true) {
        this.request = true;
        this.settObj.valueNumber = parseInt(this.settObj.valueNumber);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Settings/SaveNew?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: [this.settObj],
          message: "Opening balance save successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.visibility = false;
        }
      }
    },

    async LoadData() {
      this.isSummary = false;
      this.dataLoading = true;
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Fees/LoadMonthlySummary?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: {
          month: this.dateObj.month,
          year: this.dateObj.year,
        },
        context: this,
        subjects: true,
        token: this.$store.state.userData.token,
      });
      if (Array.isArray(res)) this.items = res;

      // console.log(this.items);
      this.dataLoading = false;
    },
    async LoadSummary() {
      this.isSummary = true;
      this.dataLoading = true;
      // FeeMonthlySummary
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Fees/LoadMonthlySummary?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: {
          month: this.dateObj.month,
          year: this.dateObj.year,
        },
        context: this,
        subjects: true,
        token: this.$store.state.userData.token,
      });
      if (Array.isArray(res)) this.summItems = res;

      // console.log(this.summItems);
      this.dataLoading = false;
    },
    flattenData(data) {
      const flattenedData = [];

      data.forEach((item) => {
        // Base information for each class, excluding total
        const baseInfo = {
          srNo: item.srNo,
          cls: item.cls,
          voucher_issued: item.voucher_issued,
          voucher_cleared: item.voucher_cleared,
          voucher_unpaid: item.voucher_unpaid,
        };

        // Create a row for each class
        const row = { ...baseInfo };

        // Add monthly_fee titles and amounts as columns
        item.monthly_fee.forEach((fee) => {
          row[fee.title] = fee.amount;
        });

        // Add other_fee titles and amounts as columns
        item.other_fee.forEach((fee) => {
          row[fee.title] = fee.amount;
        });

        // Add total as the last column
        row.total = item.total;

        flattenedData.push(row);
      });

      return flattenedData;
    },
    async exportData() {
      this.exporting = true;
      await this.LoadData();

      // Filter the data to include only entries where total > 0
      const filteredData = this.items.filter((item) => item.total > 0);

      // Flatten the filtered JSON data
      const flattenedData = this.flattenData(filteredData);

      // Calculate totals for each column
      const totals = {};
      flattenedData.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if (key !== "srNo" && key !== "cls") {
            totals[key] = (totals[key] || 0) + (row[key] || 0);
          }
        });
      });

      // Create a totals row
      const totalsRow = { srNo: "Total", cls: "" };
      Object.keys(totals).forEach((key) => {
        totalsRow[key] = totals[key];
      });

      // Insert the totals row at the end of the data
      flattenedData.push(totalsRow);

      // Create a new workbook
      const workbook = xlsx.utils.book_new();

      // Convert flattened data to a worksheet
      const worksheet = xlsx.utils.json_to_sheet(flattenedData);

      // Append the worksheet to the workbook
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");

      // Write the workbook to a file
      xlsx.writeFile(workbook, "dues-report.xlsx");

      this.exporting = false;
    },
    async exportDataOlder() {
      this.exporting = true;
      await this.LoadData();
      const flattenedData = this.flattenData(this.items);
      // console.log("Data for export", this.items);
      const workbook = xlsx.utils.book_new();
      const worksheet = xlsx.utils.json_to_sheet(flattenedData);
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "output.xlsx");
      // console.log("Excel file has been created successfully.");
      this.exporting = false;
    },

    async exportDataDeprecated() {
      this.exporting = true;
      await this.LoadData();

      const data = this.items.map((entry) => {
        // Combine monthly_fee into individual columns
        // const monthlyFee = entry.monthly_fee.reduce((acc, fee, index) => {
        //   acc[`Monthly Fee Title`] = fee.title;
        //   acc[`Monthly Fee Amount`] = fee.amount;
        //   return acc;
        // }, {});

        // // Combine other_fee into individual columns
        // const otherFee = entry.other_fee.reduce((acc, fee, index) => {
        //   acc[`Other Fee Title`] = fee.title;
        //   acc[`Other Fee Amount`] = fee.amount;
        //   return acc;
        // }, {});
        const monthlyFee = entry.monthly_fee
          .map((f) => `${f.title} - ${f.amount} `)
          .join("\n");
        const otherFee = entry.other_fee
          .map((f) => `${f.title} - ${f.amount} `)
          .join("\n");

        return {
          "Serial Number": entry.srNo,
          Class: entry.cls,
          "Voucher Issued": entry.voucher_issued,
          "Voucher Cleared": entry.voucher_cleared,
          "Voucher Unpaid": entry.voucher_unpaid,
          "Monthly Fee": monthlyFee,
          "Other Fee": otherFee,
          Total: entry.total,
        };
      });

      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "dues-summary-report.xlsx");

      this.exporting = false;
    },
  },
};
</script>
<style scoped>
.table.table-sm th {
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
